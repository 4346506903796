import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { useGoogleOneTapLogin } from '@react-oauth/google';

import {
  FacebookAuthButton,
  FormWrapper,
  GoogleAuthButton
} from 'components/forms-elements';
import { NextChakraLink } from 'components/wrappers';
import { RegisterModal } from 'components/shared';
import { useAuth } from 'context';
import SectionHeading from 'components/shared/SectionHeading';
import { useTranslation } from 'hooks';

const RequestForm = dynamic(() => import('components/forms/RequestForm'));

export const Login = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { mutateGoogleAuth }: any = useAuth();
  const { t } = useTranslation();

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      try {
        const access_token = credentialResponse.credential;
        mutateGoogleAuth({
          credential: access_token,
          type: 'onetap'
        });
      } catch (e) {
        console.error(e);
      }
    },
    onError: () => {
      console.log('Login Failed');
    }
  });
  return (
    <VStack
      align={'center'}
      py='1'
      height={{ base: 'auto', md: '100vh' }}
      justifyContent='center'
    >
      <Center w={{ base: '100%', md: '65%' }}>
        <FormWrapper>
          <SectionHeading type='h1' title={t('general.footer.company_name')} />

          <HStack
            justifyContent='center'
            gap={'6'}
            alignItems={{ base: 'center', md: 'start' }}
            mt='10'
            flexDirection={{ base: 'column-reverse', md: 'row' }}
          >
            <Box>
              <Stack align={'stretch'} spacing='4'>
                <Text as='span' fontSize={'h2'} mb='2' textAlign='center'>
                  {t('bio.create_account')}
                </Text>
                <GoogleAuthButton
                  title={t('general.login_with_google')}
                  type={'register'}
                />
                <FacebookAuthButton
                  title={t('general.login_with_facebook')}
                  type={'register'}
                />

                <Button
                  colorScheme={'dark'}
                  w='full'
                  variant={'solid'}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  {t('bio.login_with_email')}
                </Button>
                <Text color='gray.500' align='center'>
                  <br />
                  {t('bio.by_next_clickling')}{' '}
                  <NextChakraLink
                    href='https://www.arageek.com/privacy-policy'
                    isExternal
                  >
                    {t('bio.privacy_policy')}
                  </NextChakraLink>{' '}
                  {t('bio.of_arageek')}
                </Text>
              </Stack>
            </Box>
            <Divider orientation='vertical' />
            <Box>
              <Stack align={'stretch'} spacing='4'>
                <Text as='span' fontSize={'h2'} textAlign='center'>
                  {t('general.forms.login')}
                </Text>
                <RequestForm
                  type='login'
                  btnText={t('general.forms.entry')}
                  btnProps={{
                    color: 'dark.600'
                  }}
                ></RequestForm>
              </Stack>
            </Box>
          </HStack>
        </FormWrapper>
      </Center>
      <RegisterModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </VStack>
  );
};
